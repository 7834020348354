import { Injectable } from '@angular/core';
import { BookingModel } from '@app/core/models';

import { createStore, setProps, withProps } from '@ngneat/elf';
import {
  deleteAllEntities,
  getActiveEntity,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { joinRequestResult } from '@ngneat/elf-requests';
import { Observable } from 'rxjs';

export interface BookingsProps {
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'bookings' },
  withProps<BookingsProps>({
    meta: { lastRefresh: null },
  }),
  withEntities<BookingModel, '_id'>({ idKey: '_id' }),
  withActiveId(),
);

@Injectable({ providedIn: 'root' })
export class BookingRepository {
  constructor() {}

  bookings$ = store.pipe(selectAllEntities(), joinRequestResult(['bookings']));
  /* activeBooking$ = store.pipe(selectActiveEntity()); */
  activeBooking$: Observable<BookingModel | undefined> =
    store.pipe(selectActiveEntity());

  get activeBooking() {
    return store.query(getActiveEntity());
  }

  setActiveBookingId(bookingId: string) {
    store.update(setActiveId(bookingId));
  }

  updateBookings(bookings: BookingModel[]) {
    const availableBookings = bookings.filter(
      booking => new Date(booking.end) >= new Date(),
    );
    if (availableBookings && availableBookings.length) {
      const today = new Date();
      const activeBooking = availableBookings.find(booking => {
        const startDate = new Date(booking.start);
        const endDate = new Date(booking.end);
        return startDate <= today && today <= endDate;
      });

      store.update(
        upsertEntities(bookings),
        setActiveId(
          activeBooking ? activeBooking._id : availableBookings[0]._id,
        ),
        setProps({ meta: { lastRefresh: new Date() } }),
      );
    }
  }

  getActiveBooking(): BookingModel | undefined {
    return store.query(getActiveEntity());
  }

  public clear(): void {
    store.update(deleteAllEntities());
    store.update(setProps({ meta: { lastRefresh: null } }));
  }
}
