import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import {
  provideTransloco,
  TranslocoModule as TranslocoBaseModule,
} from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideTranslocoPersistTranslations } from '@jsverse/transloco-persist-translations';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoBaseModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'es', 'pt', 'fr', 'de'],
        defaultLang: (() => {
          if (typeof window === 'undefined') return 'en';
          const browserLang = window.navigator.language.split('-')[0];
          return ['en', 'es'].includes(browserLang) ? browserLang : 'en';
        })(),
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: 'en',
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: { useValue: localStorage },
    }),
    provideTranslocoPersistTranslations({
      loader: TranslocoHttpLoader,
      storage: { useValue: localStorage },
      ttl: 86400,
    }),
  ],
})
export class TranslocoModule {}
