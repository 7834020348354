import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { ServiceModel } from '@app/core/models/service.model';
import { CancelationPoliciesComponent } from '@app/shared/components/cancelation-policies/cancelation-policies.component';
import { ModalController } from '@ionic/angular';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-cancelation-policies-details',
  standalone: true,
  templateUrl: './cancelation-policies-details.component.html',
  styleUrls: ['./cancelation-policies-details.component.scss'],
  imports: [TranslocoPipe, CancelationPoliciesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CancelationPoliciesDetailsComponent {
  @Input() service!: ServiceModel;

  constructor(private _modalCtrl: ModalController) {}

  dismiss() {
    this._modalCtrl.dismiss();
  }
}
