import { Injectable } from '@angular/core';
import { ServiceModel } from '@app/core/models';

import { createStore, withProps } from '@ngneat/elf';
import {
  deleteAllEntities,
  selectActiveEntities,
  selectAllEntities,
  selectEntity,
  selectManyByPredicate,
  setActiveIds,
  upsertEntities,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import { joinRequestResult, withRequestsStatus } from '@ngneat/elf-requests';
import { Observable, map } from 'rxjs';
import { CategoriesRepository } from './categories.repository';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ServicesProps {
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'services' },
  withProps<ServicesProps>({
    meta: { lastRefresh: null },
  }),
  withEntities<ServiceModel, '_id'>({ idKey: '_id' }),
  withRequestsStatus(),
  withActiveIds(),
);

@Injectable({ providedIn: 'root' })
export class ServicesRepository {
  constructor(private categoriesRepo: CategoriesRepository) {}

  public services$ = store.pipe(
    selectAllEntities(),
    joinRequestResult(['services']),
  );
  public activeServices$: Observable<ServiceModel[] | undefined> = store.pipe(
    selectActiveEntities(),
  );

  public setServices(services: ServiceModel[]) {
    services.sort((a, b) => a.name.localeCompare(b.name));
    store.update(deleteAllEntities());
    store.update(upsertEntities(services));
    const categoriesIds = [
      ...new Set(
        services.flatMap(service =>
          service.categories.map(category => category._id),
        ),
      ),
    ];

    this.categoriesRepo.setActiveCategories(categoriesIds);
  }

  public getAllByCategoryId(categoryId: string) {
    return store.pipe(
      selectManyByPredicate(service =>
        service.categories.some(category => category._id === categoryId),
      ),
    );
  }

  public getAllByCategoryIdFiltered(categoryId: string, providedAt: string) {
    return store.pipe(
      selectManyByPredicate(
        service =>
          service.categories.some(category => category._id === categoryId) &&
          service.providedAt === providedAt,
      ),
    );
  }

  public getById(_id: string) {
    return store.pipe(selectEntity(_id));
  }

  public setActiveServices(categoryId: string) {
    store
      .pipe(
        selectAllEntities(),
        map((services: ServiceModel[]) =>
          services
            .filter(service =>
              service.categories.some(category => category._id === categoryId),
            )
            .map(service => service._id),
        ),
      )
      .subscribe(ids => {
        store.update(setActiveIds(ids));
      });
  }
}
