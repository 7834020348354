import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {
  ConfigurationService,
  FirebaseAuthenticationService,
} from '@app/core/services';

export const noAuthGuard: CanActivateFn = async () => {
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  const configurationService = inject(ConfigurationService);
  const router = inject(Router);

  const user = await firebaseAuthenticationService.getCurrentUser();
  if (user && !user.isAnonymous) {
    return router.createUrlTree(['/']);
  }

  const startAsAnonymous =
    await configurationService.getBoolean('start_as_anonymous');
  if (startAsAnonymous) {
    await firebaseAuthenticationService.signInAnonymously();
    return router.createUrlTree(['/']);
  }

  return true;
};
