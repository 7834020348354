import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'readableStatus',
})
export class HumanReadableStatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'waiting-payment':
        return 'Waiting payment';
      case 'pending-approval':
        return 'Pending approval';
      case 'confirmed':
        return 'Confirmed';
      case 'completed':
        return 'Completed';
      case 'canceled':
        return 'Canceled';
      default:
        return 'unknown status';
    }
  }
}
