import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-ion-item',
  standalone: true,
  imports: [IonicModule, FormlyModule, CommonModule],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  template: `
    <ion-item [lines]="to['itemLines'] || 'full'">
      <ng-container #fieldComponent></ng-container>
      <ion-note *ngIf="showError" slot="error">
        <formly-validation-message [field]="field"></formly-validation-message>
      </ion-note>
    </ion-item>
  `,
})
export class FormlyWrapperIonItemComponent extends FieldWrapper {}
