<ion-list>
  @if (!minimized) {
    <ion-item lines="full">
      <ion-icon slot="start" name="clock"></ion-icon>
      <ion-label>
        <h2>{{ getTimeBefore(getBiggestMinutesBefore()) }}</h2>
        <p [ngStyle]="{ color: getRefundColor(1) }">
          {{ 1 * 100 | number }}%
          {{ "components.cancelationPolicies.refund" | transloco }}
        </p>
      </ion-label>
    </ion-item>
    <ion-item
      lines="full"
      *ngFor="let rule of service?.cancellationPolicy?.rules"
    >
      <ion-icon slot="start" name="clock"></ion-icon>
      <ion-label>
        <h2>{{ getTimeBefore(rule.minutesBefore) }}</h2>
        <p [ngStyle]="{ color: getRefundColor(rule.refundPercentege) }">
          {{
            rule.refundPercentege === 0
              ? ("components.cancelationPolicies.noRefund" | transloco)
              : (rule.refundPercentege * 100 | number) +
                "% " +
                ("components.cancelationPolicies.refund" | transloco)
          }}
        </p>
      </ion-label>
    </ion-item>
  } @else {
    <ion-item
      (click)="handleCancellationPolicyModal()"
      [lines]="showAll ? 'full' : 'none'"
      button="true"
    >
      <ion-label>
        <ion-text color="medium">
          <p
            [innerHTML]="
              'components.cancelationPolicies.rules.fullRefund'
                | transloco
                  : { days: convertMinutesToDays(getBiggestMinutesBefore()) }
            "
          ></p>
        </ion-text>
      </ion-label>
    </ion-item>
  }

  <ng-container
    *ngIf="
      serviceRequest &&
      serviceRequest._id &&
      serviceRequest.payment &&
      serviceRequest.payment.totalPrice
    "
  >
    <ion-row>
      <ion-col>
        <ion-item lines="none">
          <ion-label>
            <ion-text color="secondary">
              <p>
                <b>{{
                  "components.cancelationPolicies.totalAmount" | transloco
                }}</b>
              </p>
            </ion-text>
            <h2>
              {{
                serviceRequest.payment?.totalPrice?.value
                  | currencyTransformer
                    : serviceRequest.payment?.totalPrice?.currency
              }}
            </h2>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item lines="none">
          <ion-label>
            <ion-text color="secondary">
              <p>
                <b>{{
                  "components.cancelationPolicies.toReimburse" | transloco
                }}</b>
              </p>
            </ion-text>
            <h1>
              @if (getRefundPercentage(serviceRequest.date.from) === 0) {
                {{ "components.cancelationPolicies.noRefund" | transloco }}
              } @else {
                {{
                  (serviceRequest.payment?.totalPrice?.value || 0) *
                    getRefundPercentage(serviceRequest.date.from)
                    | currencyTransformer
                      : serviceRequest.payment?.totalPrice?.currency
                }}
              }
            </h1>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-list>
