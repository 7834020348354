import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingModel } from '@app/core/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BuildingService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
  ) {}

  /**
   * Retrieves a building by its ID or code.
   * @param idOrCode - The ID or code of the building to retrieve.
   * @returns An Observable that emits a building.
   */
  getOne(idOrCode: string): Observable<BuildingModel> {
    return this._http.get<BuildingModel>(
      `${environment.api.serverUrl}/properties/building/${idOrCode}`,
    );
  }
}
