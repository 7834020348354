import { Routes } from '@angular/router';
import { authGuard, noAuthGuard } from './core';
import { dataPreloadGuard } from './core/guards';
import { onBoardingGuard } from './core/guards/on-boarding/on-boarding.guard';

export const routes: Routes = [
  {
    path: 'error',
    loadChildren: () => import('./domains/error/routes').then(m => m.routes),
  },
  {
    path: 'login',
    loadChildren: () => import('./domains/login/routes').then(m => m.routes),
    canActivate: [noAuthGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('./domains/register/routes').then(m => m.routes),
    canActivate: [noAuthGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./domains/forgot-password/routes').then(m => m.routes),
    canActivate: [noAuthGuard],
  },
  {
    path: 'complete-profile',
    loadChildren: () =>
      import('./domains/complete-profile/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'interests',
    loadChildren: () =>
      import('./domains/interests/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./domains/onboarding/routes').then(m => m.routes),
  },
  {
    path: 'interests',
    loadChildren: () =>
      import('./domains/interests/routes').then(m => m.routes),
  },
  {
    path: 'qr-scanner',
    loadChildren: () =>
      import('./domains/qr-scanner/routes').then(m => m.routes),
  },
  {
    path: 'no-bookings',
    loadChildren: () =>
      import('./domains/no-bookings/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'booking/new',
    loadChildren: () =>
      import('./domains/booking-form/routes').then(m => m.routes),
  },
  {
    path: 'booking/new/:propertyId',
    loadChildren: () =>
      import('./domains/booking-form/routes').then(m => m.routes),
    canActivate: [onBoardingGuard],
  },
  {
    path: 'payment/:serviceRequestId/:clientSecret',
    loadChildren: () => import('./domains/payment/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: 'payment/:serviceRequestId/:clientSecret/:customerSessionClientSecret',
    loadChildren: () => import('./domains/payment/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./domains/categories/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: 'services',
    loadChildren: () => import('./domains/services/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: 'reservations',
    loadChildren: () =>
      import('./domains/reservations/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: 'notifications-settings',
    loadComponent: () =>
      import(
        './domains/notifications/pages/notifications-settings/notifications-settings.page'
      ).then(m => m.NotificationsSettingsPage),
    canActivate: [authGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./domains/profile/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./domains/account/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'about',
    loadChildren: () => import('./domains/about/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
  {
    path: 'family',
    loadComponent: () =>
      import('./domains/family/pages/family/family.page').then(
        m => m.FamilyPage,
      ),
    canActivate: [authGuard],
  },
  {
    path: 'billing-address',
    loadComponent: () =>
      import(
        './domains/billing-address/pages/billing-address/billing-address.page'
      ).then(m => m.BillingAddressPage),
    canActivate: [authGuard],
  },
  {
    path: 'property',
    loadChildren: () => import('./domains/property/routes').then(m => m.routes),
    canActivate: [authGuard],
    canMatch: [dataPreloadGuard],
  },
  {
    path: '',
    loadChildren: () => import('./domains/tabs/routes').then(m => m.routes),
    canActivate: [authGuard],
  },
];
