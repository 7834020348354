import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ion-textarea',
  standalone: true,
  imports: [IonicModule, FormlyModule, ReactiveFormsModule, CommonModule],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  template: `
    <ion-textarea
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [label]="to.label"
      [labelPlacement]="'stacked'"
      [required]="to.required ?? false"
    ></ion-textarea>
  `,
})
export class FormlyFieldIonTextareaComponent extends FieldType {}
