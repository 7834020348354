import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ion-multiselect',
  standalone: true,
  imports: [IonicModule, FormlyModule, ReactiveFormsModule, CommonModule],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  template: `
    <ion-select
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      multiple="true"
      [required]="to.required ?? false"
    >
      <ion-select-option
        *ngFor="let option of selectOptions"
        [value]="option.value"
      >
        {{ option.label }}
      </ion-select-option>
    </ion-select>
  `,
})
export class FormlyFieldIonMultiSelectComponent extends FieldType {
  get selectOptions(): any[] {
    const options = this.to.options;
    return Array.isArray(options) ? options : [];
  }
}
