import { Injectable } from '@angular/core';
import { NotificationModel } from '@app/core/models/notification.model';

import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  deleteAllEntities,
  selectAllEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';

export interface NotificationsProps {
  isLoading: boolean;
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'notifications' },
  withProps<NotificationsProps>({
    isLoading: false,
    meta: { lastRefresh: null },
  }),
  withEntities<NotificationModel, 'id'>({ idKey: 'id' }),
);

@Injectable({ providedIn: 'root' })
export class NotificationsRepository {
  constructor() {}

  notifications$ = store.pipe(selectAllEntities());
  isLoading$ = store.pipe(select(state => state.isLoading));

  setIsLoading(isLoading: boolean) {
    store.update(setProps({ isLoading }));
  }

  updateNotifications(notifications: NotificationModel[]) {
    if (notifications && notifications.length) {
      store.update(
        upsertEntities(this.getSortedNotifications(notifications)),
        setProps({
          meta: { lastRefresh: new Date() },
        }),
      );
    }

    store.update(
      setProps({
        isLoading: false,
      }),
    );
  }

  deleteNotification(notificationId: string) {
    store.update(state => {
      const { [notificationId]: _, ...entities } = state.entities;
      return { ...state, entities };
    });
  }

  getSortedNotifications(notifications: NotificationModel[]) {
    return notifications.sort((a: any, b: any) => b.timestamp - a.timestamp);
  }

  public clear(): void {
    store.update(deleteAllEntities());
    store.update(setProps({ meta: { lastRefresh: null } }));
  }
}
