import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '@app/core/models';

@Pipe({
  name: 'groupDiscount',
  standalone: true,
})
export class GroupDiscountPipe implements PipeTransform {
  transform(value: number, quantity: number, discounts: Discount[]): number {
    const applicableDiscount = this.getApplicableDiscount(quantity, discounts);
    if (!applicableDiscount) return 0;

    console.log('applicableDiscount', applicableDiscount);

    return value * (applicableDiscount.percentage / 100);
  }

  private getApplicableDiscount(
    quantity: number,
    discounts: Discount[],
  ): Discount | undefined {
    if (!discounts?.length) return undefined;

    const applicableDiscount = discounts
      .filter(d => d.quantity <= quantity)
      .reduce((prev, curr) => (curr.quantity > prev.quantity ? curr : prev), {
        quantity: 0,
        percentage: 0,
      });

    return applicableDiscount.quantity ? applicableDiscount : undefined;
  }
}
