import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
} from '@angular/core';
import {
  ConfigurationService,
  FirebaseAppService,
  LiveUpdateService,
  NotificationService,
  RouterService,
  ScreenTrackingService,
  SplashScreenService,
  ThemeService,
} from '@app/core';
import { Capacitor } from '@capacitor/core';
import { Motion } from '@capacitor/motion';
import { IonApp, IonMenu, IonRouterOutlet } from '@ionic/angular/standalone';
import { HeapService } from './core/services/heap/heap.service';
import { IntercomService } from './core/services/intercom/intercom.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonMenu, IonRouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  lastX: number = 0;
  lastY: number = 0;
  lastZ: number = 0;
  shakeThreshold: number = 15;
  isDesktop: boolean = false;

  constructor(
    private readonly routerService: RouterService,
    private readonly themeService: ThemeService,
    private readonly screenTrackingService: ScreenTrackingService,
    private readonly firebaseAppService: FirebaseAppService,
    private readonly liveUpdateService: LiveUpdateService,
    private readonly splashScreenService: SplashScreenService,
    private readonly injector: Injector,
    private readonly _intercomService: IntercomService,
    private readonly heapService: HeapService,
  ) {
    this.initialize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  ngOnInit() {
    this.checkScreenSize();
  }

  private initialize(): void {
    this.liveUpdateService.initialize();
    this.routerService.initialize();
    this.themeService.initialize();
    this.screenTrackingService.initialize();
    this.firebaseAppService.initialize();
    const notificationService: NotificationService =
      this.injector.get<NotificationService>(NotificationService);
    void notificationService.initialize();
    const configurationService: ConfigurationService =
      this.injector.get<ConfigurationService>(ConfigurationService);
    void configurationService.initialize();
    void this.splashScreenService.hide();
    void this.heapService.initialize();

    if (Capacitor.isNativePlatform()) {
      this.detectShakeMobile(); // Use Capacitor for mobile (iOS/Android)
    } else {
      this.detectShakeWeb(); // Use DeviceMotionEvent for web
    }
  }

  // Capacitor-based shake detection for mobile
  detectShakeMobile() {
    Motion.addListener('accel', event => {
      const { x, y, z } = event.accelerationIncludingGravity;

      const deltaX = Math.abs(this.lastX - x);
      const deltaY = Math.abs(this.lastY - y);
      const deltaZ = Math.abs(this.lastZ - z);

      if (
        deltaX > this.shakeThreshold ||
        deltaY > this.shakeThreshold ||
        deltaZ > this.shakeThreshold
      ) {
        console.log('Shake detected on mobile!');
        this.onShake();
      }

      this.lastX = x;
      this.lastY = y;
      this.lastZ = z;
    });
  }

  // Web-based shake detection using DeviceMotionEvent
  detectShakeWeb() {
    if (window.DeviceMotionEvent) {
      window.addEventListener('devicemotion', event => {
        if (
          event.accelerationIncludingGravity &&
          event.accelerationIncludingGravity.x &&
          event.accelerationIncludingGravity.y &&
          event.accelerationIncludingGravity.z
        ) {
          const { x, y, z } = event.accelerationIncludingGravity;

          const deltaX = Math.abs(this.lastX - x);
          const deltaY = Math.abs(this.lastY - y);
          const deltaZ = Math.abs(this.lastZ - z);

          if (
            deltaX > this.shakeThreshold ||
            deltaY > this.shakeThreshold ||
            deltaZ > this.shakeThreshold
          ) {
            console.log('Shake detected on web!');
            this.onShake();
          }

          this.lastX = x;
          this.lastY = y;
          this.lastZ = z;
        }
      });
    } else {
      console.log('DeviceMotionEvent is not supported on this browser.');
    }
  }

  // Trigger action when shake is detected
  onShake() {
    this._intercomService.displayMessenger();
  }

  private checkScreenSize() {
    this.isDesktop = window.innerWidth >= 1024;
  }
}
