import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';

import {
  BookingService,
  CategoryService,
  FirebaseAuthenticationService,
  ServiceService,
} from '@app/core/services';
import { ServiceRequestService } from '@app/core/services/backend/service-request.service';
import { BookingRepository, ServiceRequestsRepository } from '@app/store';

export const dataPreloadGuard: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
): Promise<boolean> => {
  const bookingRepo = inject(BookingRepository);
  const bookingService = inject(BookingService);
  const serviceRequestRepo = inject(ServiceRequestsRepository);
  const serviceService = inject(ServiceService);
  const serviceRequestService = inject(ServiceRequestService);
  const categoryService = inject(CategoryService);
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  const router = inject(Router);
  console.log(route, segments);

  return new Promise<boolean>(async resolve => {
    const user = await firebaseAuthenticationService.getCurrentUser();
    if (!user) {
      resolve(false);
      return;
    }

    bookingRepo.activeBooking$.subscribe(booking => {
      if (!booking) {
        bookingService.getAll().subscribe(bookings => {
          bookingRepo.activeBooking$.subscribe(booking => {
            if (booking) {
              console.log('preload guard', booking);
              serviceService
                .getAllByProperty(booking.property._id, booking._id)
                .subscribe();

              serviceRequestService.getAll().subscribe(sr => {
                serviceRequestRepo.upcomingReservations(booking._id);
              });
              resolve(true);
            } else {
              const storedData = sessionStorage.getItem('bookingFormData');
              if (storedData) {
                router.navigate([
                  `/booking/new/${JSON.parse(storedData).propertyCode}`,
                ]);
              } else {
                router.navigate(['/no-bookings']);
              }
              resolve(false);
            }
          });
        });
        categoryService.getAll().subscribe();
      } else {
        resolve(true);
      }
    });
  });
};
