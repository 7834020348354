import { Pipe, PipeTransform } from '@angular/core';
import { BookingRepository } from '@app/store';
import { formatInTimeZone } from 'date-fns-tz';

@Pipe({
  name: 'propertyTz',
  standalone: true,
})
export class PropertyTimezonePipe implements PipeTransform {
  constructor(private bookingRepo: BookingRepository) {}

  transform(date?: string | number | Date, format?: string): string {
    if (!date) {
      return '';
    }

    const booking = this.bookingRepo.getActiveBooking();
    const timezone = booking?.property?.location?.timezone;

    if (!timezone) {
      return date.toString();
    }

    let dateObj: Date;
    if (typeof date === 'string' || typeof date === 'number') {
      dateObj = new Date(date);
    } else {
      dateObj = date;
    }

    // If no format is provided, return the default JS date string in the correct timezone
    if (!format) {
      return dateObj.toLocaleString('en-US', { timeZone: timezone });
    }

    return formatInTimeZone(dateObj, timezone, format);
  }
}
