import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-loading-spinner',
  standalone: true,
  imports: [IonicModule, FormlyModule, NgIf],
  template: `
    <ng-container *ngIf="to['loading']; else fieldContainer">
      <ion-spinner name="crescent"></ion-spinner>
    </ng-container>
    <ng-template #fieldContainer>
      <ng-container #fieldComponent></ng-container>
    </ng-template>
  `,
})
export class FormlyWrapperLoadingSpinnerComponent extends FieldWrapper {}
