import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventDuration',
  standalone: true,
})
export class EventdurationPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null || value < 0) return 'Invalid time';

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m`;
    }

    return result.trim();
  }
}
