<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-header>
    <ion-toolbar collapse="condense">
      <ion-title size="large" class="ion-no-padding">
        {{ "components.cancelationPolicies.title" | transloco }}
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="ion-padding-horizontal">
    <p>
      {{ "components.cancelationPolicies.description" | transloco }}
    </p>
    <app-cancelation-policies
      [service]="service"
      [minimized]="false"
    ></app-cancelation-policies>

    <ion-text color="medium">
      <p>
        <i> {{ "components.cancelationPolicies.disclaimer" | transloco }}</i>
      </p>
    </ion-text>
  </div>
</ion-content>
