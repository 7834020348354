import { Injectable } from '@angular/core';
import { SettingsRepository } from '@app/store';
import { NativeAudio } from '@capacitor-community/native-audio';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  public readonly soundEnabled$ = this.settingsRepository.soundEnabled$;

  constructor(private readonly settingsRepository: SettingsRepository) {
    settingsRepository.initialized$.subscribe(() => {
      const soundEnabled = this.getSoundEnabled();
      if (soundEnabled) {
        this.setSoundEnabled(soundEnabled);
      }
    });

    this.preloadSounds();
  }

  public setSoundEnabled(soundEnabled: boolean): void {
    this.settingsRepository.setSoundEnabled(soundEnabled);
  }

  public toggleSoundEnabled(): void {
    this.settingsRepository.setSoundEnabled(!this.getSoundEnabled());
  }

  public getSoundEnabled(): boolean | undefined {
    return this.settingsRepository.getSoundEnabled();
  }

  async preloadSounds() {
    try {
      await NativeAudio.preload({
        assetId: 'conciergeButton',
        assetPath: 'assets/sounds/bell.mp3',
        isUrl: false,
      });
    } catch (error) {
      console.error('Error preloading sound:', error);
    }
  }

  async playBellSound() {
    try {
      await NativeAudio.play({ assetId: 'conciergeButton' });
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  }
}
