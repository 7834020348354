import { Injectable } from '@angular/core';
import { TutorialModel } from '@app/core/models';

import { createStore, withProps } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntity,
  upsertEntities,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import { joinRequestResult, withRequestsStatus } from '@ngneat/elf-requests';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TutorialsProps {
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'tutorials' },
  withProps<TutorialsProps>({
    meta: { lastRefresh: null },
  }),
  withEntities<TutorialModel, '_id'>({ idKey: '_id' }),
  withRequestsStatus(),
  withActiveIds(),
);

@Injectable({ providedIn: 'root' })
export class TutorialsRepository {
  constructor() {}

  public tutorials$ = store.pipe(
    selectAllEntities(),
    joinRequestResult(['tutorials']),
  );

  public updateTutorials(tutorials: TutorialModel[]) {
    tutorials.sort((a, b) => a.name.localeCompare(b.name));
    store.update(upsertEntities(tutorials));
  }

  public getById(_id: string) {
    return store.pipe(selectEntity(_id));
  }
}
