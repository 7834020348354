import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceRequestModel } from '@app/core/models';
import { ServiceRequestsRepository } from '@app/store';
import { trackRequestResult } from '@ngneat/elf-requests';

import { from, Observable, switchMap, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { environment } from 'src/environments/environment';
import { FirebaseAuthenticationService } from '../firebase';
import { TranslocoService } from '@jsverse/transloco';

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable()
export class ServiceRequestService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _serviceRequestsRepo: ServiceRequestsRepository,
    private authService: FirebaseAuthenticationService,
    private _translocoService: TranslocoService,
  ) {}

  /**
   * Retrieves the user roles from the server.
   * @returns An observable that emits the user roles.
   */
  getAll(): Observable<ServiceRequestModel[]> {
    // Correctly await the token before making the request
    return from(this.authService.getIdToken({ forceRefresh: false })).pipe(
      switchMap(data => {
        // Use the Headers constructor correctly and include the token
        const currentLang = this._translocoService.getActiveLang();
        const headers = new Headers({
          Authorization: `Bearer ${data.token}`,
          'Accept-Language': currentLang,
        });

        return fromFetch<ServiceRequestModel[]>(
          `${environment.api.serverUrl}/service-requests`,
          {
            headers: headers,
            selector: response => response.json(),
          },
        );
      }),
      tap(serviceRequests => {
        this._serviceRequestsRepo.updateServiceRequests(serviceRequests);
      }),
      trackRequestResult(['serviceRequests'], { skipCache: true }),
    );
  }

  editDate(
    serviceRequestId: string,
    date: {
      from: Date;
      to: Date;
    },
  ): Observable<ServiceRequestModel> {
    return this._http
      .patch<ServiceRequestModel>(
        `${environment.api.serverUrl}/service-requests/${serviceRequestId}/date`,
        { date },
      )
      .pipe(
        tap(serviceRequest => {
          this._serviceRequestsRepo.updateServiceRequest(serviceRequest);
        }),
      );
  }
}
