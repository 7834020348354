import { Injectable } from '@angular/core';
import { CategoryModel } from '@app/core/models';
import { createStore, setProps, withProps } from '@ngneat/elf';
import {
  deleteAllEntities,
  getEntity,
  selectActiveEntities,
  selectAllEntities,
  selectEntity,
  setActiveIds,
  upsertEntities,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import { joinRequestResult } from '@ngneat/elf-requests';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CategoriesProps {
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'categories' },
  withProps<CategoriesProps>({ meta: { lastRefresh: null } }),
  withEntities<CategoryModel, '_id'>({ idKey: '_id' }),
  withActiveIds(),
);

@Injectable({ providedIn: 'root' })
export class CategoriesRepository {
  constructor() {}
  public categories$ = store.pipe(
    selectAllEntities(),
    joinRequestResult(['categories']),
  );

  public activeCategories$: Observable<CategoryModel[] | undefined> =
    store.pipe(selectActiveEntities());

  public setActiveCategories(categoryIds: string[]) {
    store.update(setActiveIds(categoryIds));
  }

  public getById(_id: string) {
    return store.pipe(selectEntity(_id));
  }

  public getValueById(_id: string) {
    return store.query(getEntity(_id));
  }

  public setCategories(categories: CategoryModel[]) {
    store.update(upsertEntities(categories));
  }

  public clear(): void {
    store.update(deleteAllEntities());
    store.update(setProps({ meta: { lastRefresh: null } }));
  }
}
