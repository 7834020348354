import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BookingRepository } from '@app/store';
import { App } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { CapacitorAppService } from '../capacitor';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private initialized = false;
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(
    private readonly capacitorAppService: CapacitorAppService,
    private readonly navController: NavController,
    private readonly router: Router,
    private readonly bookingRepository: BookingRepository,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public initialize(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.capacitorAppService.backButton$.subscribe(event => {
      if (event.canGoBack) {
        window.history.back();
      } else {
        void App.exitApp();
      }
    });
  }

  navigateTo(url: string, options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward([url], options);
  }

  public navigateToLoginPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/login'], options);
  }

  public navigateToRootPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/'], options);
  }

  public navigateToSelectLocationPage(
    serviceId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['services', serviceId, 'book', 'select-location'],
      options,
    );
  }

  public navigateToRegisterPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/register'], options);
  }

  public navigateToReservationsPage(
    reservationId?: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    if (reservationId) {
      return this.navigateForward(['/reservations', reservationId], options);
    } else {
      return this.navigateForward(['/reservations'], options);
    }
  }

  public navigateToMyPurchasesPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/menu/my-purchases'], options);
  }

  public navigateToForgotPasswordPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/forgot-password'], options);
  }

  public navigateToNoBookingsPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/no-bookings'], options);
  }

  public navigateToBookingsPage(
    bookingId?: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    if (bookingId) {
      return this.navigateForward(['/bookings', bookingId], options);
    } else {
      return this.navigateForward(['/bookings'], options);
    }
  }

  public navigateToOnBoardingPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/on-boarding'], options);
  }

  public navigateToWifiPage(
    bookingId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/property', bookingId, 'wifi'], options);
  }

  public navigateToPlannerPage(
    options?: NavigationOptions,
    date?: string | undefined,
  ): Promise<boolean> {
    if (date) {
      options = { ...options, queryParams: { date } };
    }
    return this.navigateForward(['/agenda'], options);
  }

  public navigateToTutorialsPage(
    bookingId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/property', bookingId, 'tutorials'], options);
  }

  /*   public async navigateToReservationsPage(
    reservationId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['reservations', reservationId], options);
  } */

  public async navigateToServiceDetailsPage(
    serviceId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['services', serviceId], options);
  }

  public async navigateToPaymentPage(
    serviceRequestId: string,
    clientSecret: string,
    customerSessionClientSecret?: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      [
        'payment',
        serviceRequestId,
        clientSecret,
        customerSessionClientSecret || '',
      ],
      options,
    );
  }

  public navigateToCategoriesPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/categories'], options);
  }

  public navigateToDashboardPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navController.navigateRoot(['/dashboard'], options);
  }

  public navigateToExplorePage(options?: NavigationOptions): Promise<boolean> {
    return this.navController.navigateRoot(['/explore'], options);
  }

  public navigateToNotificationsPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/notifications'], options);
  }

  public navigateToServicesByCategoryPage(
    categoryId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/categories', categoryId], options);
  }

  public navigateToSchedulePage(
    serviceId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/services', serviceId, 'book', 'schedule'],
      options,
    );
  }

  public navigateToAdditionalInfoPage(
    serviceId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/services', serviceId, 'book', 'additional-info'],
      options,
    );
  }

  public navigateToCheckoutPage(
    serviceId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/services', serviceId, 'book', 'checkout'],
      options,
    );
  }

  public navigateToSchedulePageForEdit(
    serviceId: string,
    reservationId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/reservation-form', serviceId, reservationId],
      options,
    );
  }

  public navigateToReservationStatusPage(
    serviceRequestId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/reservations', serviceRequestId, 'status'],
      options,
    );
  }

  public navigateToReservationPaidPage(
    serviceRequestId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(
      ['/reservations', serviceRequestId, 'paid'],
      options,
    );
  }

  public navigateToAccountPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/account'], options);
  }

  public navigateToNotificationsSettingsPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/notifications-settings'], options);
  }

  public navigateToProfilePage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/profile'], options);
  }

  public navigateToFamilyPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/family'], options);
  }

  public navigateToErrorPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/error'], options);
  }

  public navigateToInterestsPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/interests'], options);
  }

  public navigateToBillingAddressPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/billing-address'], options);
  }

  public navigateToQrScannerPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/qr-scanner'], options);
  }

  public navigateToAllServicesPage(
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/services'], options);
  }

  public navigateToBuildingPage(
    buildingId: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navigateForward(['/building', buildingId], options);
  }

  public navigateToNewBookingPage(
    propertyId?: string,
    options?: NavigationOptions,
  ): Promise<boolean> {
    if (propertyId) {
      return this.navigateForward(['/booking/new', propertyId], options);
    } else {
      return this.navigateForward(['/qr-scanner'], options);
    }
  }

  public navigateToMenuPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/menu'], options);
  }

  public navigateToAboutPage(options?: NavigationOptions): Promise<boolean> {
    return this.navigateForward(['/about'], options);
  }

  private navigateForward(
    commands: any[] | string | any,
    options?: NavigationOptions,
  ): Promise<boolean> {
    return this.navController.navigateForward(commands, options);
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }
}
