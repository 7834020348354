/* eslint-disable unicorn/prefer-top-level-await */
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  provideRouter,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { AppComponent } from '@app/app.component';
import {
  BookingService,
  CategoryService,
  ErrorHandlerService,
  ServiceService,
} from '@app/core/services';
import { routes } from '@app/routes';
import { icons } from '@app/shared';
import { TranslocoModule } from '@app/widgets/transloco';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { provideQueryClientOptions } from '@ngneat/query';
import { addIcons } from 'ionicons';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
} else {
  devTools();
}

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

// Custom icons
addIcons(
  icons.reduce((obj: { [key: string]: any }, item) => {
    obj[item.name] = `assets/icons/${item.name}.svg`;
    return obj;
  }, {}),
);

// Lottie
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';

import { ServiceRequestService } from '@app/core/services/backend/service-request.service';
import player from 'lottie-web/build/player/lottie_svg';

import { NonWorkingDayService } from '@app/core/services/backend/non-working-day.service';
import { PropertyService } from '@app/core/services/backend/property.service';
import { UserService } from '@app/core/services/backend/user.service';
import { WeatherService } from '@app/core/services/backend/weather.service';
import { LottieModule } from 'ngx-lottie';
import { NgxStripeModule } from 'ngx-stripe';

import { TutorialsService } from '@app/core/services/backend/tutorials.service';
import { QuillModule } from 'ngx-quill';

import { CommonModule } from '@angular/common';
import { FormlyFieldIonCheckboxComponent } from '@app/core/formly/types/checkbox.type';
import { FormlyWrapperIonItemComponent } from '@app/core/formly/types/field-wrapper.type';
import { FormlyFieldIonInputComponent } from '@app/core/formly/types/input.type';
import { FormlyWrapperLoadingSpinnerComponent } from '@app/core/formly/types/loading-spinner.type';
import { FormlyFieldIonMultiSelectComponent } from '@app/core/formly/types/multi-select.type';
import { FormlyFieldIonRadioComponent } from '@app/core/formly/types/radio.type';
import { FormlyFieldIonSelectComponent } from '@app/core/formly/types/select.type';
import { FormlyFieldIonTextareaComponent } from '@app/core/formly/types/textarea.type';
import { GoogleMapsService } from '@app/core/services/google-maps/google-maps.service';
import { LocaleService } from '@app/core/services/locale/locale.service';
import { FormlyModule } from '@ngx-formly/core';
export function playerFactory() {
  return player;
}

/* export function initializeDataApp(
  appResolver: AppResolver,
): () => Promise<any> {
  return (): Promise<any> => {
    return firstValueFrom(
      appResolver.resolve().pipe(
        catchError(error => {
          console.error('Data loading failed', error);
          return of(null);
        }),
      ),
    );
  };
} */

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      TranslocoModule,
      LottieModule.forRoot({ player: playerFactory }),
      NgxStripeModule.forRoot(environment.stripeKey),
      QuillModule.forRoot(),
      FormlyModule.forRoot({
        validationMessages: [
          { name: 'required', message: 'This field is required' },
        ],
        wrappers: [
          { name: 'form-field', component: FormlyWrapperIonItemComponent },
          {
            name: 'loading-spinner',
            component: FormlyWrapperLoadingSpinnerComponent,
          },
        ],
        types: [
          { name: 'input', component: FormlyFieldIonInputComponent },
          { name: 'radio', component: FormlyFieldIonRadioComponent },
          { name: 'select', component: FormlyFieldIonSelectComponent },
          {
            name: 'multiselect',
            component: FormlyFieldIonMultiSelectComponent,
          },
          {
            name: 'checkbox',
            component: FormlyFieldIonCheckboxComponent,
          },
          {
            name: 'textarea',
            component: FormlyFieldIonTextareaComponent,
          },
        ],
      }),
    ),
    CategoryService,
    BookingService,
    ServiceService,
    ServiceRequestService,
    WeatherService,
    PropertyService,
    UserService,
    NonWorkingDayService,
    TutorialsService,
    GoogleMapsService,
    /*  AppResolver, */
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    /*     {
      provide: APP_INITIALIZER,
      useFactory: initializeDataApp,
      deps: [AppResolver],
      multi: true,
    }, */
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({
      mode: 'ios',
      backButtonDefaultHref: '/',
      useSetInputAPI: true, //  required for input signals on controller based modals
      backButtonIcon: 'chevron-left',
    }),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withRouterConfig({
        /**
         * @see https://github.com/angular/angular/issues/13586
         */
        canceledNavigationResolution: 'computed',
      }),
    ),
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    }),
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getLocale(),
      deps: [LocaleService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
}).catch(error => console.log(error));
