import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { FirebaseAuthenticationService } from '@app/core/services';
import { SettingsPageService } from '@app/domains/menu/services';

import { firstValueFrom } from 'rxjs';

export const authGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  const settingsService = inject(SettingsPageService);
  const router = inject(Router);

  const url = state.url;
  console.log('setting redirectUrl', url);
  firebaseAuthenticationService.redirectUrl = url;

  const showOnBoarding = await firstValueFrom(settingsService.showOnBoarding$);

  if (showOnBoarding) {
    return router.createUrlTree(['on-boarding']);
  }

  const user = await firebaseAuthenticationService.getCurrentUser();
  if (user) {
    return true;
  }

  return router.createUrlTree(['login']);
};
