import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';

type AlertType = 'default' | 'info' | 'warning' | 'danger' | 'success';

@Component({
  selector: 'app-alert',
  standalone: true,
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  imports: [IonIcon, CommonModule],
})
export class AlertComponent {
  @Input() margin?: string = '15px 0';
  @Input() title?: string;
  @Input() description?: string;
  @Input() type: AlertType = 'default';
  @Input() showIcon: boolean = true;
  @Input() size: 'small' | 'regular' = 'regular';

  get icon(): string {
    const icons: Record<AlertType, string> = {
      default: 'info-circle',
      info: 'info-circle',
      warning: 'info-circle',
      danger: 'info-circle',
      success: 'check-circle',
    };
    return icons[this.type];
  }

  get alertClass(): string {
    return `alert-${this.type}`;
  }
}
