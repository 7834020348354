import { Injectable } from "@angular/core";
import { BookingRepository, CategoriesRepository, ServicesRepository, ServiceRequestsRepository } from "@app/store";
import { NotificationsRepository } from "@app/store/notifications.repository";
import { TutorialsRepository } from "@app/store/tutorials.repository";
import { WeatherRepository } from "@app/store/weather.repository";

@Injectable({ providedIn: 'root' })
export class StoreCleanupService {
  constructor(
    private bookingRepo: BookingRepository,
    private categoriesRepo: CategoriesRepository,
    private notificationsRepo: NotificationsRepository,
    private servicesRepo: ServicesRepository,
    private serviceRequestsRepo: ServiceRequestsRepository,
  ) {}

  clearAllStores(): void {
    // Clear all repositories
    this.bookingRepo.clear();
    this.categoriesRepo.clear();
    this.notificationsRepo.clear();
    this.servicesRepo.clear();
    this.serviceRequestsRepo.clear();
    
    // Clear local storage except for specific items you want to keep
    const itemsToKeep = ['translocoLang']; // Add any items you want to preserve
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && !itemsToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    }
    
    // Clear session storage
    sessionStorage.clear();
  }
}
