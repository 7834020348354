import { Pipe, PipeTransform } from '@angular/core';

// Import the currencies data
import { currencies } from '../data/currencies';

@Pipe({
  name: 'currencyTransformer',
  standalone: true,
})
export class CurrencyTransformerPipe implements PipeTransform {
  transform(value?: number, currencyCode: string = 'USD'): string | null {
    // Find the currency from the provided code
    const currency =
      currencies[currencyCode?.toUpperCase() as keyof typeof currencies];
    if (!value) {
      //console.error('Invalid value');
      return '-';
    }

    if (!currency) {
      //console.error('Invalid currency code');
      return '-';
    }

    // Format the value with the correct decimal digits
    const formattedValue = value.toFixed(currency.decimal_digits);

    // Return the formatted currency string
    return `${currency.symbol} ${formattedValue}`;
  }
}
