import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ion-checkbox',
  standalone: true,
  imports: [IonicModule, FormlyModule, ReactiveFormsModule],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  template: `
    <ion-checkbox
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      labelPlacement="end"
      [required]="to.required ?? false"
    >
      {{ to.label }}
    </ion-checkbox>
  `,
})
export class FormlyFieldIonCheckboxComponent extends FieldType {}
