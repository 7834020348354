import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ion-radio',
  standalone: true,
  imports: [IonicModule, FormlyModule, ReactiveFormsModule, CommonModule],
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  template: `
    <ion-list>
      <ion-list-header *ngIf="to.label">{{ to.label }}</ion-list-header>
      <ion-radio-group
        [formControl]="$any(formControl)"
        [formlyAttributes]="field"
        [required]="to.required ?? false"
      >
        <ion-item *ngFor="let option of radioOptions">
          <ion-label>{{ option.label }}</ion-label>
          <ion-radio [value]="option.value"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  `,
})
export class FormlyFieldIonRadioComponent extends FieldType {
  get radioOptions(): any[] {
    const options = this.to.options;
    return Array.isArray(options) ? options : [];
  }
}
