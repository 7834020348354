// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: {
    major: '1',
    minor: '93',
    patch: '6',
  },
  build: {
    changeset: '74d73d3',
  },
  firebase: {
    apiKey: 'AIzaSyBHy0Nk44hId48eD0NeqcuPcKrtB_LHR0E',
    authDomain: 'develop.app.guestgenie.com',
    projectId: 'guestgenie-develop',
    storageBucket: 'guestgenie-develop.appspot.com',
    messagingSenderId: '226788270369',
    appId: '1:226788270369:web:7e0feaf2e025efb07c1e60',
    vapidKey:
      'BGPizmCm11s4JWh9f0V5_3OWLsrZm0W9YfGXlB1gWkQloSyO3nZPPZkXzFS6ndZ1X58Qoy7brLGsuIIq4qTrr68',
  },
  firebaseDatabase: {
    apiKey: 'AIzaSyC9fgPCtMPezUBNjKUAIcyEGoCI8q0mhS0',
    authDomain: 'guestgenie-backoffice-develop.firebaseapp.com',
    projectId: 'guestgenie-backoffice-develop',
    storageBucket: 'guestgenie-backoffice-develop.appspot.com',
    messagingSenderId: '2058910583',
    appId: '1:2058910583:web:0f5f422d8d6059b0e6ff72',
    appName: 'guestgenie-backoffice-develop',
  },
  localStorageKeyPrefix: 'ionstarter_',
  googleApiKey: 'AIzaSyDLWoVuBd7arn9QtI7Zajem4-lrgcHSumw',
  stripeKey:
    'pk_test_51PBGEl2LuUb40Y0z2rNQEknV4ZOmezcHc4uX6pqfiPA96131gPlLpSqAtyoNdRcgyC4yMfo4BFmtGyecSVgSA7qf00Dgt5HNtq',
  api: {
    serverUrl: 'https://api-develop.app.guestgenie.com/api/v1',
  },
  roomServiceCategory: '662fef01043a1c57776d085e',
  intercomWorkspace: 'kh5l45bz',
  unsplashAccessKey: 'h2oZokzcT6qkbWkEUfZxFs83FTonNgjfPSZePiTbxds',
  heapAppId: '3466441017',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
