import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { RouterService } from '@app/core';
import { CategoryModel } from '@app/core/models';
import {
  BookingRepository,
  CategoriesRepository,
  ServicesRepository,
} from '@app/store';
import { IonicModule } from '@ionic/angular';
import { TranslocoPipe } from '@jsverse/transloco';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-categories-grid',
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    LottieModule,
    TranslocoPipe,
    AlertComponent,
  ],
  templateUrl: './categories-grid.component.html',
  styleUrls: ['./categories-grid.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CategoriesGridComponent {
  @Input() showHeader: boolean = true;

  categoriesFiltered: CategoryModel[] = [];
  isProcessing = true;

  emptyOptions: AnimationOptions = {
    path: '/assets/animations/empty.json',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  constructor(
    public categoriesRepo: CategoriesRepository,
    public bookingRepo: BookingRepository,
    public servicesRepo: ServicesRepository,
    private _routerService: RouterService,
  ) {}

  ngOnInit() {
    console.log('categories component init');
  }

  navigateToAllServicesPage() {
    this._routerService.navigateToAllServicesPage();
  }

  handleCategoryClick(category: CategoryModel) {
    this._routerService.navigateToServicesByCategoryPage(category._id);
  }

  getCategoryLabelWithoutSpaces(label: string) {
    if (!label) return '';
    return label?.replace(/\s+/g, '-').replace(/&/g, 'and').toLowerCase();
  }
}
