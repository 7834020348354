import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { SettingsPageService } from '@app/domains/menu/services';

import { firstValueFrom } from 'rxjs';

export const onBoardingGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const settingsService = inject(SettingsPageService);
  const router = inject(Router);

  const url = state.url;

  const showOnBoarding = await firstValueFrom(settingsService.showOnBoarding$);

  if (showOnBoarding) {
    return router.createUrlTree(['on-boarding'], {
      queryParams: { redirectUrl: url },
    });
  }

  return true;
};
