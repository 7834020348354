<!-- Categories GRID -->
<ng-container
  *ngIf="
    !(categoriesRepo.categories$ | async)?.isLoading &&
    !(servicesRepo.services$ | async)?.isLoading &&
    (servicesRepo.services$ | async)?.data as services
  "
>
  @if (services.length > 0) {
    <!-- ..// Categories -->
    <section id="categories" class="ion-padding-horizontal">
      @if (showHeader) {
        <header>
          <h5 class="ion-no-margin">
            {{ "domain.explore.components.categories.title" | transloco }}
          </h5>
          <ion-button
            size="small"
            fill="clear"
            (click)="navigateToAllServicesPage()"
            >{{
              "domain.explore.components.categories.button.seeAllServices"
                | transloco
            }}</ion-button
          >
        </header>
      }

      <div
        id="categories-grid"
        *ngIf="categoriesRepo.activeCategories$ | async as categories"
      >
        @for (category of categories; track category._id; let i = $index) {
          <ion-card
            color="light"
            button="true"
            (click)="handleCategoryClick(category)"
            [id]="getCategoryLabelWithoutSpaces(category.label)"
          >
            <ion-card-header>
              <ion-icon [name]="category.icon"></ion-icon>
            </ion-card-header>

            <ion-card-content>
              {{ category.label }}
            </ion-card-content>
          </ion-card>
        }
      </div>
    </section>
    <!-- ..// Categories -->
  } @else {
    <img src="/assets/illustrations/no-services.png" alt="No services" />
    <section id="no-categories-available" class="ion-padding">
      <app-alert
        [title]="
          'domain.explore.components.categories.noServices.title' | transloco
        "
        [description]="
          'domain.explore.components.categories.noServices.message' | transloco
        "
      ></app-alert>
    </section>
  }
</ng-container>
<!-- ..// Categories GRID -->

<!-- Categories skeleton -->
<ng-container
  *ngIf="
    (categoriesRepo.categories$ | async)?.isLoading ||
    (servicesRepo.services$ | async)?.isLoading
  "
>
  <section id="categories" class="ion-padding-horizontal">
    @if (showHeader) {
      <header class="ion-margin-bottom">
        <h5 class="ion-no-margin">
          {{ "domain.explore.components.categories.title" | transloco }}
        </h5>
      </header>
    }

    <div id="categories-grid">
      @for (category of [1, 2, 3, 4, 5]; track category) {
        <ion-card
          color="light"
          style="min-height: 110px; display: flex; flex-direction: column"
        >
          <ion-card-header>
            <ion-skeleton-text
              [animated]="true"
              style="width: 40px; height: 40px"
            ></ion-skeleton-text>
          </ion-card-header>

          <ion-card-content>
            <ion-skeleton-text
              [animated]="true"
              style="width: 100px"
            ></ion-skeleton-text>
          </ion-card-content>
        </ion-card>
      }
    </div>
  </section>
</ng-container>
<!-- ..// Categories skeleton -->

<!-- No services available -->
<section
  id="no-services-available"
  class="ion-padding"
  *ngIf="
    !(categoriesRepo.categories$ | async)?.isLoading &&
    !(servicesRepo.services$ | async)?.isLoading &&
    !(categoriesRepo.categories$ | async)?.data?.length
  "
>
  <ng-lottie width="200px" height="200px" [options]="emptyOptions" />
  <h2>
    {{ "domain.explore.components.categories.noServices.title" | transloco }}
  </h2>
  <ion-text color="medium">
    <p>
      {{
        "domain.explore.components.categories.noServices.message" | transloco
      }}
    </p>
  </ion-text>
</section>
<!-- ..// No services available -->
