import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import {
  CancellationPolicyModel,
  ServiceModel,
  ServiceRequestModel,
} from '@app/core/models';
import { CancelationPoliciesDetailsComponent } from '@app/shared/modals/cancelation-policies-details/cancelation-policies-details.component';
import { CurrencyTransformerPipe } from '@app/shared/pipes';
import { ModalController } from '@ionic/angular';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { differenceInMinutes } from 'date-fns';
import { DividerComponent } from '../divider/divider.component';
import { ReservationDetailsCardComponent } from '../reservation-details-card/reservation-details-card.component';

@Component({
  selector: 'app-cancelation-policies',
  standalone: true,
  templateUrl: './cancelation-policies.component.html',
  styleUrls: ['./cancelation-policies.component.scss'],
  imports: [
    CommonModule,
    DividerComponent,
    ReservationDetailsCardComponent,
    CurrencyTransformerPipe,
    TranslocoPipe,
  ],
  providers: [ModalController],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CancelationPoliciesComponent {
  @Input() service?: ServiceModel;
  @Input() serviceRequest?: ServiceRequestModel;
  @Input() minimized: boolean = false;

  public presentingElement: HTMLElement | undefined = undefined;

  public showAll: boolean = false;

  constructor(
    private _translocoService: TranslocoService,
    private _modalCtrl: ModalController,
  ) {
    console.log('CancelationPoliciesComponent', this.service);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.presentingElement = document.querySelector(
        '.service-details-page',
      ) as HTMLElement;
    }, 100);
  }

  async handleCancellationPolicyModal() {
    const modal = await this._modalCtrl.create({
      component: CancelationPoliciesDetailsComponent,
      presentingElement: this.presentingElement,
      componentProps: {
        service: this.service,
      },
    });
    await modal.present();
  }

  getTimeBefore(minutesBefore: number): string {
    const sortedRules = this.service?.cancellationPolicy.rules.sort(
      (a, b) => b.minutesBefore - a.minutesBefore,
    );

    // Handle cases dynamically
    if (sortedRules?.length) {
      // Case for more than the greatest rule (default case for 100% refund)
      if (minutesBefore > sortedRules[0].minutesBefore) {
        const days = this.convertMinutesToDays(minutesBefore);
        return this.service?.cancellationPolicy?.defaultLabel
            ? this.service?.cancellationPolicy?.defaultLabel
          : this._translocoService.translate(
              'components.cancelationPolicies.rules.moreThan',
              { days },
            );
      }

      for (let i = 0; i < sortedRules.length; i++) {
        if (minutesBefore >= sortedRules[i].minutesBefore) {
          const currentRule = sortedRules[i];
          const nextRule = sortedRules[i + 1];

          if (nextRule) {
            // Calculate the range between two rules
            const currentDays = this.convertMinutesToDays(
              currentRule.minutesBefore,
            );
            const nextDays = this.convertMinutesToDays(nextRule.minutesBefore);

            if (currentDays > nextDays) {
              return currentRule.label
                ? currentRule.label
                : this._translocoService.translate(
                    'components.cancelationPolicies.rules.between',
                    { days1: nextDays, days2: currentDays },
                  );
            } else {
              const currentHours = Math.floor(currentRule.minutesBefore / 60); // Convert to hours
              const nextHours = Math.floor(nextRule.minutesBefore / 60);
              return currentRule.label
                ? currentRule.label
                : this._translocoService.translate(
                    'components.cancelationPolicies.rules.between',
                    { days1: nextHours, days2: currentHours },
                  );
            }
          } else {
            // When it matches the last rule, show within hours
            /*             const hours = Math.floor(currentRule.minutesBefore / 60); */
            if (currentRule.label) {
              return currentRule.label;
            } else {
              return this._translocoService.translate(
                'components.cancelationPolicies.rules.within24hours',
              );
            }
          }
        }
      }
    }

    return this.service?.cancellationPolicy?.defaultLabel
      ? this.service?.cancellationPolicy?.defaultLabel
      : 'No matching rule found';
  }

  getBiggestMinutesBefore(): number {
    if (this.service?.cancellationPolicy) {
      return Math.max(
        ...this.service.cancellationPolicy.rules.map(
          cancelationPolicy => cancelationPolicy.minutesBefore + 1,
        ),
      );
    }
    return 0;
  }

  convertMinutesToDays(minutes: number): number {
    return Math.floor(minutes / 1440);
  }

  // New method to calculate refund percentage based on reservation date
  getRefundPercentage(reservationDate?: Date | string): number {
    let cancellationPolicy: CancellationPolicyModel;
    let refundPercentage = 1;
    if (reservationDate) {
    const difference = differenceInMinutes(reservationDate, new Date());

    cancellationPolicy = (this.service as ServiceModel)
      .cancellationPolicy as CancellationPolicyModel;

    if (cancellationPolicy) {
      const rulesSorted = cancellationPolicy.rules.sort(
        (a, b) => a.minutesBefore - b.minutesBefore,
      );
      for (const rule of rulesSorted) {
        if (difference <= rule.minutesBefore) {
          refundPercentage = rule.refundPercentege;
          break;
        }
      }
    } else {
      console.log(
        `Service request ${this.serviceRequest?._id} has no cancellation policy`,
      );
      refundPercentage = 0;
    }
    } else {
      refundPercentage = 0;
    }
    return refundPercentage;
  }

  // Get a color for refund percentage display (e.g., green for full refund, red for no refund)
  getRefundColor(refundPercentege: number): string {
    if (refundPercentege === 1) {
      return 'green';
    } else if (refundPercentege === 0) {
      return 'red';
    } else {
      return 'orange';
    }
  }
}
