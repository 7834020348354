import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryModel } from '@app/core/models';
import { CategoriesRepository } from '@app/store/categories.repository';
import { TranslocoService } from '@jsverse/transloco';
import { trackRequestResult } from '@ngneat/elf-requests';
import { Observable, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { environment } from 'src/environments/environment';

// Models

@Injectable()
export class CategoryService {
  constructor(
    private _http: HttpClient,
    private _categoriesRepo: CategoriesRepository,
    private _translocoService: TranslocoService,
  ) {}

  /**
   * Retrieves all categories from the server.
   * @returns An Observable that emits an array of CategoryModel objects.
   */
  getAll(): Observable<CategoryModel[]> {
    const currentLang = this._translocoService.getActiveLang();
    return fromFetch<CategoryModel[]>(
      `${environment.api.serverUrl}/services/categories`,
      {
        selector: response => response.json(),
        headers: {
          'Accept-Language': currentLang,
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      tap(categories => {
        this._categoriesRepo.setCategories(categories);
      }),
      trackRequestResult(['categories']),
    );
  }
}
