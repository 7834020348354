import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingModel, WeatherModel } from '@app/core/models';
import { WeatherRepository } from '@app/store/weather.repository';
import { EMPTY, Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirebaseAuthenticationService } from '../firebase';

/**
 * Service responsible for handling weather for a booking
 */
@Injectable()
export class WeatherService {
  constructor(
    private _http: HttpClient,
    private _weatherRepo: WeatherRepository,
    private firebaseAuthService: FirebaseAuthenticationService
  ) {}

  getWeather(booking: BookingModel): Observable<WeatherModel> {
    return this.firebaseAuthService.currentUser$.pipe(
      switchMap(user => {
        if (!user) {
          // Return empty observable if not authenticated
          return EMPTY;
        }
    let params = new HttpParams();

    params = params.set('lat', booking.property.location.geometry.lat);
    params = params.set('lon', booking.property.location.geometry.lng);

    return this._http
      .get<WeatherModel>(`${environment.api.serverUrl}/weather`, { params })
      .pipe(
        tap(weather => {
          console.log(weather);
          this._weatherRepo.setWeather(weather || null);
        }),
      );
      })
    );
  }
}
