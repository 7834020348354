import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private localeSubject = new BehaviorSubject<string>('en'); // Default locale
  locale$ = this.localeSubject.asObservable();

  constructor(
    private transloco: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.listenToLanguageChanges();
  }

  private listenToLanguageChanges() {
    this.transloco.langChanges$.subscribe(lang => {
      this.updateLocale(lang);
    });
  }

  private updateLocale(lang: string) {
    console.log(`Updating locale to: ${lang}`);

    // Register the correct locale
    switch (lang) {
      case 'es':
        registerLocaleData(localeEs);
        break;
      case 'fr':
        registerLocaleData(localeFr);
        break;
      case 'pt':
        registerLocaleData(localePt);
        break;
      case 'de':
        registerLocaleData(localeDe);
        break;
      default:
        registerLocaleData(localeEn);
        lang = 'en'; // Fallback to English
        break;
    }

    // Update the BehaviorSubject
    this.localeSubject.next(lang);

    // Update <html lang="..."> for accessibility & SEO
    this.document.documentElement.lang = lang;
  }

  // Ensure this always returns a string (not an Observable)
  getLocale(): string {
    return this.localeSubject.value;
  }
}
