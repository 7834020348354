import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translocoService: TranslocoService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const userLanguage = this.translocoService.getActiveLang(); // Get stored language

    // Clone request and add Accept-Language header
    const modifiedReq = req.clone({
      setHeaders: {
        'Accept-Language': userLanguage,
      },
    });

    return next.handle(modifiedReq);
  }
}
