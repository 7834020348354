import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { HttpClient } from '@angular/common/http';

import { CapacitorBrowserService } from '../capacitor';

import { environment } from '@env/environment';
import { Heap, HeapLogger, LogLevel } from '@heap/heap-capacitor-bridge';

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  private isNative: boolean;

  constructor(
    private _http: HttpClient,
    private _capacitorBrowserService: CapacitorBrowserService,
  ) {
    this.isNative = Capacitor.isNativePlatform();
  }

  initialize() {
    if (this.isNative) {
      // Native platform (iOS/Android)
      Heap.startRecording(environment.heapAppId);
      HeapLogger.setLogLevel(LogLevel.Debug);
    } else {
      // Web platform
      this.initializeWebHeap();
    }
  }

  private initializeWebHeap() {
    (window as any).heapReadyCb = (window as any).heapReadyCb || [];
    (window as any).heap = (window as any).heap || [];

    (window as any).heap.load = function (envId: string, clientConfig?: any) {
      (window as any).heap.envId = envId;
      (window as any).heap.clientConfig = clientConfig = clientConfig || {};
      (window as any).heap.clientConfig.shouldFetchServerConfig = false;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://cdn.us.heap-api.com/config/${envId}/heap_config.js`;

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript?.parentNode?.insertBefore(script, firstScript);

      const methods = [
        'init',
        'startTracking',
        'stopTracking',
        'track',
        'resetIdentity',
        'identify',
        'getSessionId',
        'getUserId',
        'getIdentity',
        'addUserProperties',
        'addEventProperties',
        'removeEventProperty',
        'clearEventProperties',
        'addAccountProperties',
        'addAdapter',
        'addTransformer',
        'addTransformerFn',
        'onReady',
        'addPageviewProperties',
        'removePageviewProperty',
        'clearPageviewProperties',
        'trackPageview',
      ];

      const createMethodProxy = function (methodName: string) {
        return function () {
          const args = Array.prototype.slice.call(arguments, 0);
          (window as any).heapReadyCb.push({
            name: methodName,
            fn: function () {
              if ((window as any).heap[methodName]) {
                (window as any).heap[methodName].apply(
                  (window as any).heap,
                  args,
                );
              }
            },
          });
        };
      };

      for (let i = 0; i < methods.length; i++) {
        (window as any).heap[methods[i]] = createMethodProxy(methods[i]);
      }
    };

    // Initialize Heap for web
    (window as any).heap.load(environment.heapAppId);
  }

  /**
   * Tracks an event in Heap Analytics
   * @param eventName - The name of the event to track
   * @param properties - Optional event properties to attach
   */
  trackEvent(eventName: string, properties?: Record<string, any>) {
    if (!eventName) {
      console.warn('Event name is required to track an event in Heap.');
      return;
    }

    if (this.isNative) {
      // Native platform (iOS/Android)
      Heap.track(eventName, properties);
    } else {
      (window as any).heap.track(eventName, properties);
    }
  }

  /**
   * Identifies a user in Heap Analytics
   * @param userId - Unique identifier for the user (e.g., user ID, email)
   * @param properties - Optional user properties to attach
   */
  identify(userId: string, properties?: Record<string, any>) {
    if (!userId || this.isInvalidIdentifier(userId)) {
      console.warn('Invalid identifier provided to Heap.identify()');
      return;
    }

    if (this.isNative) {
      // Native platform (iOS/Android)
      Heap.identify(userId);
      if (properties) {
        Heap.addUserProperties(properties);
      }
    } else {
      (window as any).heap.identify(userId);
      if (properties) {
        (window as any).heap.addUserProperties(properties);
      }
    }
  }

  /**
   * Adds properties to the current user
   * @param properties - User properties to attach
   */
  addUserProperties(properties: Record<string, any>) {
    if (this.isNative) {
      Heap.addUserProperties(properties);
    } else if (environment.production) {
      (window as any).heap.addUserProperties(properties);
    }
  }

  private isInvalidIdentifier(identifier: string): boolean {
    const invalidIdentifiers = [
      'anonymous',
      'guest',
      'undefined',
      'null',
      'true',
      'false',
      'not_authenticated',
      'NaN',
      '',
      '0',
      '[object Object]',
      'None',
      'none',
      'Email',
      'email',
    ];

    return invalidIdentifiers.includes(identifier.toLowerCase());
  }
}
