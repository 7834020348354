import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { PlatformService } from '../platform/platform.service';
import { SplashScreenService } from '../splash-screen/splash-screen.service';

import * as LiveUpdates from '@capacitor/live-updates';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class LiveUpdateService {
  public readonly isUpdateAvailable$: Observable<boolean>;

  private readonly isUpdateAvailableSubject = new ReplaySubject<boolean>(1);

  constructor(
    private readonly platformService: PlatformService,
    private readonly splashScreenService: SplashScreenService,
  ) {
    this.isUpdateAvailable$ = this.isUpdateAvailableSubject.asObservable();
  }

  public async initialize(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      // Register event to fire each time user resumes the app
      App.addListener('resume', async () => {
        if (localStorage['shouldReloadApp'] === 'true') {
          await LiveUpdates.reload();
        } else {
          const result = await LiveUpdates.sync();
          localStorage['shouldReloadApp'] = result.activeApplicationPathChanged;
        }
      });

      // First sync on app load
      const result = await LiveUpdates.sync();
      localStorage['shouldReloadApp'] = result.activeApplicationPathChanged;
    }
  }
}
