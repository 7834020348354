import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { BookingRepository } from '@app/store/booking.repository';
import { Intercom } from '@capacitor-community/intercom';
import { environment } from '@env/environment';
import {
  Intercom as IntercomWeb,
  onShow,
  onUnreadCountChange,
  show,
  showArticle,
  showNewMessage,
  showSpace,
  shutdown,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';
import { Observable } from 'rxjs';
import { CapacitorBrowserService } from '../capacitor';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private isNative: boolean;
  private unreadCountSubject = new BehaviorSubject<number>(0);
  public unreadCount$ = this.unreadCountSubject.asObservable();

  constructor(
    private _http: HttpClient,
    private _capacitorBrowserService: CapacitorBrowserService,
    private _bookingRepo: BookingRepository,
  ) {
    this.isNative = Capacitor.isNativePlatform();

    this._bookingRepo.activeBooking$.subscribe(activeBooking => {
      if (activeBooking) {
        this.waitForIntercomInitialization().then(() => {
          onUnreadCountChange((count: number) => {
            console.log('unread count', count);
            this.unreadCountSubject.next(count);
          });
          this.updateAttributes({
            Property: activeBooking.property.name,
            'Property address': activeBooking.property.location.address,
            'Check-in date': new Date(activeBooking.start).toISOString(),
            'Check-out date': new Date(activeBooking.end).toISOString(),
            Guests: [
              { type: 'Adults', count: activeBooking.numberOfGuests?.adults },
              {
                type: 'Children',
                count: activeBooking.numberOfGuests?.children,
              },
              {
                type: 'Infants',
                count: activeBooking.numberOfGuests?.infants,
              },
            ],
          });
        });
      }
    });
  }

  initialize(user: any) {
    if (user) {
      if (this.isNative) {
        // Native platform (iOS/Android)
        Intercom.registerIdentifiedUser({ email: user.email });
      } else {
        this.getIntercomIdentity().subscribe(
          identity => {
            if (identity) {
              IntercomWeb({
                app_id: environment.intercomWorkspace,
                name: user?.displayName ? user?.displayName : '',
                email: identity.email,
                user_hash: identity.hash,
                avatar: user?.photoURL,
                language_override:
                  localStorage.getItem('translocoLang') ?? 'en',
                signed_up_at: user?.metadata.creationTime
                  ? Math.floor(
                      new Date(user.metadata.creationTime).getTime() / 1000,
                    )
                  : undefined,
                created_at: user?.metadata.creationTime
                  ? Math.floor(
                      new Date(user.metadata.creationTime).getTime() / 1000,
                    )
                  : undefined,
                hide_default_launcher: true,
                custom_launcher_selector: '.custom-launcher',
                app_type: 'guests',
              });

              if (environment.production) {
                setTimeout(() => {
                  update({
                    app_version:
                      environment.version.major +
                      '.' +
                      environment.version.minor +
                      '.' +
                      environment.version.patch,
                  });
                }, 500);
              }
            } else {
              console.warn(
                'Failed to initialize Intercom: Identity not available',
              );
            }
          },
          error => {
            console.error('Error initializing Intercom:', error);
          },
        );
      }
    }
  }

  onShow(callback: () => void) {
    if (this.isNative) {
      /* Intercom.onShow(callback); */
    } else {
      onShow(callback);
    }
  }

  showMessages() {
    if (this.isNative) {
      Intercom.displayInAppMessages();
    } else {
      showSpace('messages');
    }
  }

  async helpCenter() {
    if (this.isNative) {
      Intercom.displayHelpCenter();
    } else {
      showSpace('help');
      /* await this._capacitorBrowserService.open({
        url: 'https://help.guestgenie.com',
      }); */
    }
  }

  newMessage(message?: string) {
    if (this.isNative) {
      Intercom.displayMessageComposer({ message: message ?? '' });
    } else {
      showNewMessage(message ?? '');
    }
  }

  displayMessenger() {
    if (this.isNative) {
      Intercom.displayMessenger();
    } else {
      show();
    }
  }

  logout() {
    if (this.isNative) {
      Intercom.logout();
    } else {
      shutdown();
    }
  }

  updateUser(user: { email?: string; name?: string }) {
    if (this.isNative) {
      Intercom.updateUser(user);
    } else {
      update(user);
    }
  }

  updateAttributes(attributes: any) {
    if (this.isNative) {
      Intercom.updateUser(attributes);
    } else {
      update(attributes);
    }
  }

  trackEvent(eventName: string, metaData?: any) {
    if (this.isNative) {
      Intercom.logEvent({ name: eventName, data: metaData });
    } else {
      trackEvent(eventName, metaData);
    }
  }

  private getIntercomIdentity(): Observable<{ email: string; hash: string }> {
    return this._http.get<{ email: string; hash: string }>(
      `${environment.api.serverUrl}/intercom/identity-hash`,
    );
  }

  shutdown() {
    if (this.isNative) {
      Intercom.logout();
    } else {
      shutdown();
    }
  }

  showArticle(articleId: string) {
    if (this.isNative) {
      Intercom.displayArticle({ articleId });
    } else {
      showArticle(articleId);
    }
  }

  reportAnIssue() {
    if (this.isNative) {
      Intercom.logEvent({
        name: 'report-a-problem',
      });
    } else {
      trackEvent('report-a-problem');
    }
  }

  private waitForIntercomInitialization(): Promise<void> {
    return new Promise(resolve => {
      const checkIntercom = () => {
        if (this.isNative || (window as any).Intercom) {
          resolve();
        } else {
          setTimeout(checkIntercom, 100);
        }
      };
      checkIntercom();
    });
  }
}
